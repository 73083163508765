.TVLoginView {
    position: absolute;
    width: calc(100% - 1px);
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    margin: 0;
    text-align: center;
    color: white;
}

@media only screen and (min-width: 500px) {
    .TVLoginView {
      position: fixed;
      margin-left: (50% - 250px);
      width: 500px; 
    }
  }
  
.TVLoginView__image {
    width: 100px;
    margin-top: 70px;
    left: calc(50% - 50px);
}

.TVLoginView__hello {
    width: 100%;
    margin-top: 180px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 800;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.TVLoginView__authorize {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.TVLoginView__links {
    width: 100%;
    text-align: center;
}

.TVLoginView__button {
    width: calc(100% - 20px);
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: var(--dkfont-family-primary);
}

.TVLoginView__input {
    width: calc(100% - 26px);
    border: none;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    outline: none;
    font-size: 16px;
    font-weight: 900;
    font-family: var(--dkfont-family-primary);
}

.TVLoginView__ad {
    color: white !important;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 12px;
}

.TVLoginView__ad--link {
    color: #91c5c5 !important;
}

.TVLoginView__divider {
    margin-top: 100px;
}

.TVLoginView__bar {
    height: 50px;
    border: none;
}

.TVLoginView__bar__button {
    display: inline-block;
    width: 120px;
    background-color: transparent;
}

.TVLoginView__bar__button--left {
    float: left;
    text-align: left;
    padding-left: 3px;
}

.TVLoginView__bar__button--right {
    float: right;
    text-align: right;
    padding-right: 3px;
}