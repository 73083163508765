@import "./font/index.css";

:root {
  --tvcolor-dark: #2d2c2e;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: var(--dkfont-family-primary), 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #161618;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  outline: none !important;
  text-emphasis: none;
  text-decoration: none;
}
