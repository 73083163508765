.TVServiceView {
  position: absolute;
  width: calc(100% - 1px);
  height: 100%;
  margin: 0;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media only screen and (min-width: 500px) {
    .TVServiceView {
      position: fixed;
      margin-left: (50% - 250px);
      width: 500px; 
    }
  }
  
.TVLoginView__image {
    width: 100px;
    position: absolute;
    top: 20px;
    left: calc(50% - 50px);
}

.TVServiceView__hello {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 800;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.TVLoginView__authorize {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.TVLoginView__links {
    width: 100%;
}

.TVServiceView__button {
    display: inline-block;
    
    width: 80px !important;
    height: 80px !important;

    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    border-radius: 5px;
    font-size: 45px;
    font-weight: 700;
    background-color: #58585e;
    border: 1px solid #81818a;
    font-family: var(--dkfont-family-primary);
    text-align: center;
}

.TVServiceViewInput__input {
    width: calc(100% - 40px);
    border: none;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    outline: none;
    font-size: 65px;
    font-weight: 900;
    font-family: var(--dkfont-family-primary);
}

.TVLoginView__ad {
    color: white !important;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 12px;
}

.TVLoginView__ad--link {
    color: #91c5c5 !important;
}