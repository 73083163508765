.TVClientView {
    position: absolute;
    width: calc(100% - 1px);
    height: 100%;
    margin: 0;
    color: white;
    text-align: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (min-width: 500px) {
    .TVClientView {
    position: fixed;
    margin-left: (50% - 250px);
    width: 500px; 
    }
}
.TVClientView__hint {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 12px;
    font-weight: 700;
}

.TVClientView__header {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--dkfont-family-primary);
    color: white;
}

.TVClientView__header--large {
    margin-top: 60px;
    font-size: 19px;
    font-weight: 900;
    font-family: var(--dkfont-family-primary);
    color: white;
}

.TVClientView__code {
    margin-top: 10px;
    font-size: 40px;
    font-weight: 500;
    color: #f5c907;
}

.TVClientView__card {
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
    padding: 20px;
    border: 0.5px solid #292929;
    background-color: rgb(0, 0, 0);
    box-shadow: 0px 0px 2px 1px rgba(29, 29, 29, 0.233);
    border-radius: 10px;
    height: 200px;
}

.TVClientView__prizecard {
    padding-top: 35px;
    color: var(--tvcolor-dark);
    background-color: #ffb922;
}

.TVClientView__card__content {
    text-align: center;
}

.TVClientView__prizecard__icon {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.TVClientView__prizecard__content {
    font-weight: 800;
    color: black;
}

.TVClientView__prizecard__content__button {
    margin-top: 15px;
    background-color: transparent;
    color: black;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    box-shadow: none;
    font-family: var(--dk-primary-font);
    font-size: 14px;
    font-weight: 800;
}

.TVClientView__prizecard__content__button:hover {
    color: #26252c;
}

.TVClientView__card__content__icon {
    width: 50px;
    margin-top: 20px;
    display: inline-block;
}

.TVClientView__logo {
    position: absolute;
    width: 50px;
    left: calc(50% - 25px);
}

.TVClientView__logo--big {
    position: absolute;
    width: 80px;
    left: calc(50% - 40px);
}

.TVClientView__card__content__header {
    text-align: center;
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    font-weight: 800;
}

.TVClientView__card__content__icons {
    text-align: center;
    align-items: center;
}

.TVClientView__image {
    position: absolute;
    right: calc(50% - 35px);
    top: 10px;
    height: 70px;
}