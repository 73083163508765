.TVProcess__card {
    background-color: white;
    color: black;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
    border-radius: 0px;
    text-align: left;
    border-top: 15px dotted var(--tvcolor-dark);
}

.TVProcess__card__header {
    font-size: 18px;
    font-weight: 800;
}

.TVProcess__card__divider {
    height: 1px;
    border-top: 2px solid var(--tvcolor-dark);
    margin-top: 10px;
    margin-bottom: 20px;
}

.TVProcess__card____hint {
    color: #555566;
    font-size: 14px;
    font-weight: 600;
    margin-top: -10px;
    margin-bottom: 30px;
}

.TVProcess__card__element {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.TVProcess__card__element--total {
    font-size: 45px;
    font-weight: 900;
    margin-top: 5px;
    margin-bottom: 20px;
}

.TVProcess__card__element__delete {
    width: 40px;
    height: 20px;
    color: red;
    background-color: transparent;
    float: right
}