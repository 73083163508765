.TVButton {
    display: block;
    border: none;
    outline: none;
    box-shadow: none;
    color: white;
    background-color: var(--tvcolor-dark);
    width: calc(100% - 20px);
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: var(--dkfont-family-primary);
}

.TVButton--inline {
    display: inline-block;
}